import React from 'react';

import Layout from '../components/layout';
import SEO from '../components/seo';

const LicenseNumbers = () => {
	return (
		<Layout header={'menu'}>
			<SEO
				title="License Numbers"
				canonicalUrl='https://www.getmybubble.com/license-numbers/'
				description="License Numbers" />

			<div className="flexColumn carrierCommonPage">
				<div className="company-info-container container-fluid">
					<div className="row">
						<div className="col-lg-12" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
							<div>
								<h1 className="carrier-plan-heading about-heading" style={{ lineHeight: '1.55', textAlign: 'center' }}>
                  License numbers
								</h1>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div className="container">
				<div className="row">
					<div className="col-lg-3">
					</div>

					<div className="col-lg-6 license-padding">
						<table className="table">
							<tbody>
								<tr className="style-table-border">
									<td>Arizona</td>

									<td className="align-second-col">3001297520</td>
								</tr>

								<tr className="style-table-border">
									<td>California</td>

									<td className="align-second-col">6004436</td>
								</tr>

								<tr className="style-table-border">
									<td>Colorado</td>

									<td className="align-second-col">773730</td>
								</tr>

								<tr className="style-table-border">
									<td>Florida</td>

									<td className="align-second-col">L116076</td>
								</tr>

								<tr className="style-table-border">
									<td>Georgia</td>

									<td className="align-second-col">3576370</td>
								</tr>

								{/* <tr className="style-table-border">
                  <td>Georgia</td>

                  <td className="align-second-col">6004436</td>
                </tr> */}

								<tr className="style-table-border">
									<td>Idaho</td>

									<td className="align-second-col">3002822470</td>
								</tr>

								<tr className="style-table-border">
									<td>Indiana</td>

									<td className="align-second-col">3796910</td>
								</tr>


								{/* <tr className="style-table-border">
                  <td>Kentucky</td>

                  <td className="align-second-col">1259383</td>
                </tr> */}

								<tr className="style-table-border">
									<td>New Jersery </td>

									<td className="align-second-col">3001297502</td>
								</tr>

								<tr className="style-table-border">
									<td>North Carolina</td>

									<td className="align-second-col">3001301469</td>
								</tr>

								<tr className="style-table-border">
									<td>Ohio</td>

									<td className="align-second-col">1369369</td>
								</tr>

								{/* <tr className="style-table-border">
                  <td>Oregon</td>

                  <td className="align-second-col">3002045596</td>
                </tr> */}

								<tr className="style-table-border">
									<td>Pennsylvania</td>

									<td className="align-second-col">1064485</td>
								</tr>

								<tr className="style-table-border">
									<td>South Carolina</td>

									<td className="align-second-col">3002051006</td>
								</tr>

								{/* <tr className="style-table-border">
                  <td>Tennessee</td>

                  <td className="align-second-col">3002051022</td>
                </tr> */}

								<tr className="style-table-border">
									<td>Tennessee</td>

									<td className="align-second-col">3002051022</td>
								</tr>

								<tr className="style-table-border">
									<td>Texas ( P&amp;C )</td>

									<td className="align-second-col">2711667</td>
								</tr>

								<tr className="style-table-border">
									<td>Texas ( Life )</td>

									<td className="align-second-col">2711664</td>
								</tr>


								{/* <tr className="style-table-border">
                  <td>Virginia</td>

                  <td className="align-second-col">151382</td>
                </tr>

                <tr className="style-table-border">
                  <td>Washington</td>

                  <td className="align-second-col">1128756</td>
                </tr>

                <tr className="style-table-border">
                  <td>Wisconsin</td>

                  <td className="align-second-col">3002032323</td>
                </tr> */}
							</tbody>
						</table>

					</div>

					<div className="col-lg-3">
					</div>
				</div>
			</div>

		</Layout>

	);
};

export default LicenseNumbers;
